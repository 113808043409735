/* utilities */
.default-title {
  padding: 24px;
  font-size: 18px;
  line-height: 26px;
  border-bottom: 1px solid #dddddd;
  font-weight: bold;
}

html {
  scrollbar-color: rgba(0, 0, 0, 0.16) rgba(0, 0, 0, 0.08);
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color:  rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

body {
  margin: 0;
  height: 100%;
  font-family:"Noto Sans CJK JP", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック","MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
  background-color: #e6eaf0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

.MuiSelect-select ,.MuiOutlinedInput-root {
  background-color: #fff !important;
}

#root {
  width: 100%;
  height: 100%;
}

/* アプリケーション全体を通してボタンにドロップシャドウを付けない */
button.MuiButton-root {
  box-shadow: none;
  /* ボタンがアルファベットの時に強制的に大文字になるのを解除する */
  text-transform: none;
}

/* タブがアルファベットの時に強制的に大文字になるのを解除する */
a.MuiButtonBase-root {
  text-transform: none;
}

button.MuiButton-root:hover {
  box-shadow: none;
}
